import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { Theme } from "./chakraStyle/Theme";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/cabin/500.css";
import "@fontsource/cabin/600.css";
import "@fontsource/noto-sans-jp/500.css";
import "@fontsource/inter";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";
import "@fontsource/roboto";

// app.upacity.jpへのリダイレクト処理を追加
if (window.location.hostname !== 'app.upacity.jp' && window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
  window.location.href = 'https://app.upacity.jp' + window.location.pathname + window.location.search;
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  //<StrictMode>
  <BrowserRouter>
    <ChakraProvider theme={Theme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
  //</StrictMode>
);

reportWebVitals();
